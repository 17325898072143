<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ resellerService.name }}</h3>
    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ resellerService.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="resellerService.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PRICE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(resellerService.price) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-8">
            <dl
              class="row"
              v-for="(tax, key) in resellerService.taxes"
              :key="key"
            >
              <dt class="col-sm-4">{{ tax.name }}</dt>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_PERCENTAGE">
                {{ tax.value }} %
              </dd>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="resellerService.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="resellerService.created_at">
            {{ $formatDate(resellerService.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="resellerService.updated_at">
            {{ $formatDate(resellerService.updated_at) }}
          </dd>
        </dl>
      </div>
      <div class="col-3">
        <img
          v-if="resellerService.picture"
          :src="`${resellerService.picture}`"
          class="argon-image mb-2"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";

export default {
  name: "reseller-service-view-global",

  components: {},

  props: ["resellerService"],

  data() {
    return {
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerService(resellerService) {},
  },
};
</script>
