<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!resellerService">
      <span class="loader"></span>
    </span>
    <reseller-service-form
      v-if="resellerService"
      :loading="loading"
      :resellerServiceData="resellerService"
      :formErrors="formErrors"
      @resellerServiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import ResellerServiceForm from "../partials/ResellerServiceForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { ResellerServiceForm },

  mixins: [alertLeave],

  props: {
    resellerServiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      resellerService: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "resellerServices/get",
          this.resellerServiceId
        );
        this.resellerService =
          this.$store.getters["resellerServices/resellerService"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(resellerService) {
      this.loading = true;
      const resellerServiceData = cloneDeep(resellerService);

      try {
        await this.$store.dispatch(
          "resellerServices/update",
          resellerServiceData
        );
        this.$notify({
          type: "success",
          message: this.$t("RESELLER_SERVICES.RESELLER_SERVICE_UPDATED"),
        });
        const resellerService = await this.$store.getters[
          "resellerServices/resellerService"
        ];
        this.$emit("onViewResellerService", resellerService, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
