<template>
  <div class="container-fluid">
    <reseller-service-form
      :loading="loading"
      :resellerServiceData="resellerService"
      :formErrors="formErrors"
      @resellerServiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultResellerService from "../defaultResellerService";
import ResellerServiceForm from "../partials/ResellerServiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ResellerServiceForm,
  },

  mixins: [alertLeave],

  data() {
    const resellerService = cloneDeep(defaultResellerService);
    resellerService.taxes = cloneDeep(defaultTaxes);
    return {
      resellerService: resellerService,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(resellerService) {
      this.loading = true;

      const resellerServiceData = cloneDeep(resellerService);
      delete resellerServiceData.id;

      try {
        await this.$store.dispatch("resellerServices/add", resellerServiceData);
        this.$notify({
          type: "success",
          message: this.$t("RESELLER_SERVICES.RESELLER_SERVICE_ADDED"),
        });
        const resellerService = await this.$store.getters[
          "resellerServices/resellerService"
        ];
        this.$emit("onViewResellerService", resellerService, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
